import React, { ReactNode, useEffect, useRef } from 'react'
import './layout.scss'
import ScrollSnap from 'scroll-snap'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

interface Props {
  children: ReactNode
}
var prevScrollpos = 0
const Layout = ({ children }: Props): JSX.Element => {
  const container = useRef<HTMLDivElement>(null)
  // const bindScrollSnap = () => {
  //   const element = container.current
  //   const snap = new ScrollSnap(element, {
  //     snapDestinationY: '95%',
  //     timeout: 50,
  //     time: true,
  //   })
  //   snap.bind();
  // }
  // dirty hack
  useEffect(() => {
    // bindScrollSnap();
    container.current!.addEventListener('scroll', (e: any) => {

      var currentScrollPos = e.srcElement.scrollTop
      if (e.srcElement.scrollTop < 20) {
        document.getElementsByClassName('root')[0].style.top = '0'
        document.getElementsByClassName('root')[0].classList.remove('fixed')
        return
      }

      if (prevScrollpos > currentScrollPos) {
        document.getElementsByClassName('root')[0].style.top = '0'
        document.getElementsByClassName('root')[0].classList.add('fixed')
      } else {
        document.getElementsByClassName('root')[0].style.top = '-150px'
        document.getElementsByClassName('responsive_menu')[0].classList.remove("is_open_menu")
        // document.getElementsByClassName('root')[0].classList.remove("fixed")
      }
      prevScrollpos = currentScrollPos
      // if (oldScrollTop < e.srcElement.scrollTop && oldScrollTop - e.srcElement.scrollTop < 150) {
      //   oldScrollTop = e.srcElement.scrollTop;
      // }
      // const diff = oldScrollTop - e.srcElement.scrollTop;
      // console.log("old", oldScrollTop);
      // console.log("new", e.srcElement.scrollTop);

      // if (diff > 150) {
      //   document.getElementsByClassName('root')[0].classList.toggle("fixed")
      // }
      // if (oldScrollTop < e.srcElement.scrollTop) {

      //   document.getElementsByClassName('root')[0].classList.toggle("fixed")
      // }
    })
  }, [])

  return (
    <>
      <main ref={container}>{children}</main>
    </>
  )
}

export default Layout
